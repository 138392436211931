const Noodl = require("@noodl/noodl-sdk");
const XLSX = require('xlsx');

const ArraysToExcelNode = Noodl.defineNode({
    category: "My Utils",
    name: "ArraysToExcelNode",
    inputs: {
        Array1: "array",
        Array2: "array",
        FileName: "string",
    },
    signals: {
        exportAsExcel: function () {
            let workbook = XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet([this.inputs.Array1, this.inputs.Array2]);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, this.inputs.FileName || 'output.xlsx');
        },
    },
});

Noodl.defineModule({
    nodes: [ArraysToExcelNode],
    setup() {
        console.log("Module setup");
    },
});